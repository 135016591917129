import React from "react"
import { graphql } from "gatsby"
import Image from "safe-gatsby-image-plugin"
import Layout from "../components/layout/layout"
import Row from "../components/row"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Slider from "../components/slider"
import Form from "./../components/form"
import Heading from "./../components/heading"
import Button from "../components/button"
import { seoDataFromPage } from "components/utils/data-formatting"

import l from "./../components/layout/layout.module.scss"
import r from "./role.module.scss"

const Role = ({ pageContext, data: queryData }) => {
  const { title, slug: role } = pageContext
  const meta = seoDataFromPage(queryData.wordpressPage)

  const { acf } = queryData.wordpressPage
  const { hero, info, form } = acf

  const renderRoleSpecificContent = role => {
    if (role === "architect") {
      return (
        <div className={l.grid_2}>
          <div>
            {acf.architect.specifications.map(item => (
              <div className={r.specifications} key={item.key}>
                <span>{item.key}</span>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
          <div>
            {acf.architect.specifications_2.map(item => (
              <div className={r.specifications} key={item.key}>
                <span>{item.key}</span>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      )
    } else if (role === "timmerfabriek") {
      return (
        <div className={r.videoWrapper}>
          <iframe
            title="Instructievideo"
            src="https://www.youtube-nocookie.com/embed/kFDyS5gDQKA"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            allowFullScreen
          ></iframe>
        </div>
      )
    } else {
      return <Image data={acf.consument.info_image} />
    }
  }

  return (
    <Layout>
      <SEO title={title} meta={meta} />
      <Hero data={hero} />
      <Row bgColor="anthracite">
        <div className={r.featured_wrapper}>
          <div className={r.featured}>
            <div className={r.inner}>
              <div className={r.title}>{info.featured.title}</div>
              <div
                dangerouslySetInnerHTML={{ __html: info.featured.content }}
              />
            </div>
          </div>
        </div>
      </Row>
      <Row id={info.title} customClass={r.full_height_row} bgColor="anthracite">
        <div className={[l.grid_2, r.bg_row_content].join(" ")}>
          <div>
            <Heading>{info.title}</Heading>
            <div
              className={l.text_under_heading}
              dangerouslySetInnerHTML={{ __html: info.text }}
            />
            <Button color="light" data={info.button} />
          </div>
          <div className={r.right}>{renderRoleSpecificContent(role)}</div>
        </div>
      </Row>
      <Row id="contact" bgColor="white" customClass={r.contact_section}>
        <div className={[l.grid_2, r.bg_row_content].join(" ")}>
          <div>
            <Heading>{form.title}</Heading>
            <Form btnLabel={form.btn_label} fields={form.wordpress_fields} />
          </div>
          <div>
            <Heading>{form.slider_title}</Heading>
            <Slider slides={form.slider} />
          </div>
        </div>
      </Row>
    </Layout>
  )
}

export default Role

// add page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      acf {
        hero {
          title
          text
          bg {
            ...ImageFullWidth
          }
          button {
            btn_label
            btn_link
          }
        }
        info {
          featured {
            title
            content
          }
          title
          text
          button {
            btn_label
            btn_link
          }
        }
        aannemer {
          video
        }
        architect {
          specifications {
            value
            key
          }
          specifications_2 {
            key
            value
          }
        }
        consument {
          info_image {
            ...ImageDefault
          }
        }
        form {
          title
          slider_title
          wordpress_fields {
            width
            type
            label
          }
          btn_label
          slider {
            ...ImageDefault
          }
        }
      }
      ...SEOData
    }
  }
`
